<template>
	<div>
		<p class="m-right-title">{{$route.params.type == 1 ? '答题练习记录' : '考试记录'}}</p>
		<div class="m-list" v-loading="loading">
			<div class="m-item" v-for="(item,index) in list">
				<div class="m-item-con">
					<p class="u-line-1 m-item-font1">{{item.examination_paper_name}}</p>
					<p class="m-item-font2 u-m-t-5">考试时间：{{item.created_at}}</p>
				</div>
				<div class="u-flex u-flex-1 u-row-right">
					<div class="m-item-btn">分数：{{item.score}}</div>
				</div>
			</div>
		</div>
		<div class="u-flex u-row-center u-m-b-60">
			<el-pagination
			  background
			  layout="prev, pager, next"
			  @current-change="pageChange"
			  :total="count">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {to_link} from "@/router/api"
	import {getTestRecords} from "@/api/center.js"
	export default {
		data(){
			return {
				loading:false,
				params:{
					page:1,
					pageNum:10,
				},
				list:[],
				count:0
			}
		},
		mounted() {
			this.getList()
		},
		methods:{
			to_link,
			pageChange(e){
				if(e == this.params.page) return false;
				this.params.page = e
				this.getList()
			},
			async getList(){
				this.loading = true
				let res = await getTestRecords(this.params)
				setTimeout(()=>{
					this.loading = false
				},500)
				if(res.code == 200){
					this.list = res.data
					this.count = res.count || 0
				}else if(res.code == 0){
					this.$message.error(res.message)
					this.$router.back()
				}else{
					this.$message.error(res.message)
				}
			}
		}
	}
</script>

<style lang="scss" scoped> 
	.m-right-title{
		display: flex;
		height: 64px;
		line-height: 20px;
		padding-top: 32px;
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #000000;
		padding-left: 38px;
		border-bottom: 1px solid #EEEEEE;
		box-sizing: border-box;
	}
	.m-list{
		display: flex;
		flex-direction: column;
		padding-top: 20px;
		padding-left: 38px;
		padding-right: 38px;
		padding-bottom: 60px;
		.m-item{
			display: flex;
			margin-right: 22px;
			box-sizing: content-box;
			padding: 22px 0;
			cursor: pointer;
			border-bottom: 1px dashed #D1D1D1;
			.m-item-font1{
				font-size: 14px;
				color: #505050;
				line-height: 22px;
			}
			.m-item-font2{
				font-size: 12px;
				color: #939393;
				line-height: 22px;
			}
			.m-item-jindu{
				display: flex;
				color: #D6102A;
				font-size: 14px;
			}
			.m-item-con{
				display: flex;
				flex-direction: column;
				width: 650px;
				justify-content: space-between;
				
			}
			.m-item-time{
				display: flex;
				align-items: center;
				color: #7C7C7C;
				font-size: 14px;
				line-height: 14px;
			}
			.m-item-btn{
				display: flex;
				align-items: center;
				justify-content: center;
				width: 117px;
				height: 35px;
				background: #F8A310;
				border-radius: 18px;
				font-size: 14px;
				color: #fff;
			}
			.m-item-btn-extend{
				background: #D6102A;
			}
		}
	}
</style>

<style lang="scss">
	.m-item-con{
		.el-progress-bar__inner{
			background-color: #D6102A;
		}
	}
</style>
